<template>
  <div class='flex flex-col justify-center items-center h-screen overflow-y-auto'>
    <img
      src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/icola_main.png'
      class='full-width-inside-layout-container z-0 fixed top-0 bottom-0 opacity-70'
      style='width:100vw;max-width:100vw;height:100vh;object-fit:cover;object-position:0 1rem;' />
    <div class='relative p-4 mx-2 lg:p-8 max-w-5xl lg:mx-auto bg-white rounded-md shadow-md  overflow-y-auto'>
      <div class='border p-8 mt-4 lg:mt-0'>
        <img 
          src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/icola_main_text.png'
          class='w-full lg:w-2/5 px-4' />
        <h3 
          class='tagline-with-background font-bold mt-2 lg:mt-4 rounded-full text-white italic text-left px-2 py-1 lg:px-4 lg:py-2 inline-block'>
          Advancing Lipid and Atherosclerosis Research to the Next Level
        </h3>
        <img 
          src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/icola_date_and_location.png'
          class='w-full lg:w-2/5 mt-2 lg:mt-4 ml-2 lg:ml-4' />
      </div>
      <div v-if='alreadyRegisteredMyself' 
        class='py-8'>
        <registration-done-message
          class='bg-gray-100 rounded-md p-16'
          :is-korean='isKorean' />
      </div>
      <div v-else-if='icolaWelcomeReceptionAtCapacity || true' 
        class='p-8 lg:p-16 rounded text-center bg-gray-100'>
        <h1 class='text-lg lg:text-3xl font-bold mb-4 mt-4 px-4'>Welcome Reception Registration is Full!</h1> 
        <div class='p-4 rounded text-center uppercase text-sm'>
          We are sorry to inform you that the Welcome Reception is at capacity.
        </div>
      </div>
      <div v-else-if='isRegistrationPeriod' 
        class='py-8'>
        <h1 class='text-lg lg:text-3xl font-bold mb-4 lg:mb-8'>Welcome Reception Registration</h1> 
        <div class='bg-gray-100 p-8 mb-4 lg:mb-8'>
          <ul class='list-disc list-inside'>
            <li class='mb-2'><span class='font-bold underline'>Welcome Reception</span> has limited capacity and may be closed earlier than anticipated, <span class='font-bold underline'>so make sure to apply not too late.</span></li>
          </ul>
        </div>
        <edit-form-field
          class='mb-4 w-full' 
          v-for='(formField,index) in signupFormFields'
          :key='`${formField.keyName}-${index}`'
          :edit-form-field='formField'
          :show-requirement-indicator-on-top='showRequirementIndicatorOnTop'
          :is-horizontal-layout='isHorizontalLayout'
          :value='fieldValue(formField.category, formField.keyName)'
          :requirement-indicator-helper-text='requirementIndicatorHelperText'
          @update-edit-form-field-value='updateFormFieldValue(formField.category, formField.keyName, ...arguments)' />
        <edit-form-field
          class='mb-4 w-full' 
          v-for='(formField,index) in registrationFormFields'
          :key='`${formField.keyName}-${index}`'
          :edit-form-field='formField'
          :show-requirement-indicator-on-top='showRequirementIndicatorOnTop'
          :is-horizontal-layout='isHorizontalLayout'
          :value='fieldValue(formField.category, formField.keyName)'
          :requirement-indicator-helper-text='requirementIndicatorHelperText'
          @update-edit-form-field-value='updateFormFieldValue(formField.category, formField.keyName, ...arguments)' />
          <div v-for='(term, index) in terms'
            :key='`term-${index}`'
            class='text-xs border rounded-md mb-6 w-full'>
            <div class='bg-gray-50 p-4 overflow-y-auto max-h-56' v-html='term.text'></div>
            <label class='border-t p-4 flex flex-row justify-start items-center gap-x-0.5 text-sm'>
              <input type='checkbox' :name='`checkbox-${index}`' v-model='term.agreed'>
              <span class='px-1'>{{ term.confirmationText }}</span>
            </label>
          </div>
        <div>
          <div class='border rounded-t-md bg-gray-50 p-4 w-full mb-6'>
            <h2 class='text-lg font-semibold mb-4 text-gray-900'>{{ registrationFeeBoxTitle }}</h2>
            <div class='flex flex-row justify-between mb-2 text-gray-900'>
              <h4>Welcome Reception</h4><p> {{ formattedFeeAmount(welcomeReceptionFeeAmount) }}</p>
            </div>
            <div class='flex flex-row justify-between mb-2 text-gray-900'>
              <h4>Total</h4><p>{{ formattedFeeAmount(totalRegistrationFee) }}</p>
            </div>
            <div class='flex flex-row justify-between mb-2' v-if='paymentGateway === "bank_transfer"'>
              <h4>Bank Name</h4>
              <p class='text-gray-900 rounded font-semibold'>Shinhan Bank</p>
            </div>
            <div class='flex flex-row justify-between mb-2' v-if='paymentGateway === "bank_transfer"'>
              <h4>Account Number</h4>
              <p v-if='isKorean' class='text-gray-900 rounded font-semibold'>100-033-623706</p>
              <p v-else class='text-gray-900 rounded font-semibold'>180-005-911078</p>
            </div>
            <div class='flex flex-row justify-between mb-2' v-if='paymentGateway === "bank_transfer"'>
              <h4>Account Holder</h4>
              <p v-if='isKorean' class='text-gray-900 rounded font-semibold'>한국지질동맥경화학회(The Korean Society of Lipid and Atherosclerosis)</p>
              <p v-else class='text-gray-900 rounded font-semibold'>The Korean Society of Lipid and Atherosclerosis</p>
            </div>
          </div>
          <button 
            class='px-4 py-3 hover:shadow-md uppercase border rounded-md submit-button w-full mb-6'
            @click='submitSignup'
            :disabled='disabledRegistrationButton'
            :class='disabledRegistrationButtonClass'>
            {{ registrationButtonText }}
          </button>
          <ul v-if='!showRequirementIndicatorOnTop'
            class='list-disc list-inside text-red-600 text-sm'>
            <div v-for='formField in [...this.signupFormFields, ...this.registrationFormFields]'
              :key='formField.keyName'>
              <li v-if='isFieldValutEmpty(formField)'>
                {{ formField.name }} {{requirementIndicatorHelperText}}
              </li>
            </div>
          </ul>
          <ul class='list-disc list-inside text-red-600 text-sm'>
            <div v-for='requirement in additionalRequirementsIndicators'
              :key='requirement.keyName'>
              <li>
                {{ requirement.metaData.indicatorText }}
              </li>
            </div>
          </ul>
        </div>
      </div>
      <div v-else v-html='registrationPeriodOverHtmlContent'></div>
    </div>
    <modal
      name='registration-payment-modal'
      :adaptive='true'
      :click-to-close='canClickToCloseRegistrationModal'
      height='auto'
      :scrollable='true'
      classes='relative'
      @opened='initializePaymentModal'
      @closed='redirectIfFinished'>
      <button v-if='canClickToCloseRegistrationModal'
        class='z-30 absolute top-3 right-3' 
        @click="$modal.hide('registration-payment-modal')">
        <x-icon class='text-black' />
      </button>
      <div v-if='registrationPaymentModalComponent == "bank-transfer"'>
        <div v-if='isKorean' class='p-8 lg:p-16 overflow-y-auto flex-grow'>
          <div class='max-w-2xl'>
            <h1 class='text-xl font-semibold mb-8'>ICoLA2023 Welcome Reception에 등록해 주셔서 감사합니다</h1>
            <div class='px-12 py-8 rounded' style='background-color: #FAFAFA;'>
              <ul class='list-disc'>
                <li class='py-1'>등록비: {{ formattedFeeAmount(totalRegistrationFee) }}</li>
                <li class='py-1'>아래의 계좌로 입금하여 주십시오.</li>
                <li class='py-1'>입금자와 등록자의 성함이 다를 경우, 등록 확인이 불가하니 <span class='font-semibold text-red-600'>반드시 등록자의 성함으로 입금 부탁드립니다.</span></li>
                <li class='py-1'>등록 기간 내 미입금 시 등록이 취소될 수 있음을 안내해 드립니다.</li>
                <li class='py-1'><span class='font-semibold text-red-600'>입금완료 및 등록 완료 확인까지 시일이 소요</span>될 수 있으며, 확인 시 등록완료 메일을 받게 됩니다.</li>
                <li class='py-1'>계좌 정보: <span class='inline-block p-2 bg-gray-200 text-gray-900 rounded font-semibold'>신한은행 100-033-623706</span>
                <li class='py-1'>예금주: 한국지질동맥경화학회(The Korean Society of Lipid and Atherosclerosis)</li>
              </ul>
            </div>
            <router-link :to='{name: "Home"}' class='block mt-8 w-full lg:w-48 text-center bg-gray-900 text-white py-3 px-2 rounded-md hover:shadow-lg text-sm'>홈으로 가기</router-link>
          </div>
        </div>
        <div v-else class='p-8 lg:p-16 overflow-y-auto flex-grow'>
          <div class='max-w-2xl'>
            <h1 class='text-xl font-semibold mb-8'>Thank you for registering ICoLA2023 Welcome Reception</h1>
            <div class='px-12 py-8 rounded' style='background-color: #FAFAFA;'>
              <ul class='list-disc'>
                <li class='py-1'>Registration Fee: {{ formattedFeeAmount(totalRegistrationFee) }}</li>
                <li class='py-1'>Please transfer the amount to the bank account below.</li>
                <li class='py-1'><span class='font-semibold text-red-600'>The registered name and depositor name must match. </span>Otherwise, we will be unable to confirm your payment.</li>
                <li class='py-1'>If your payment is not confirmed within the early registration period, your registration will be cancelled.</li>
                <li class='py-1'><span class='font-semibold text-red-600'>Please allow for a few days to confirm the deposit. </span>You will recieve the confirmation email.</li>
                <li class='py-1'>Bank Name / Account Number: <span class='inline-block p-2 bg-gray-200 text-gray-900 rounded font-semibold'>Shinhan Bank 180-005-911078</span>
                <li class='py-1'>Account Holder: The Korean Society of Lipid and Atherosclerosis</li>
              </ul>
            </div>
            <router-link :to='{name: "Home"}' class='uppercase block mt-8 w-full lg:w-48 text-center bg-gray-900 text-white py-3 px-2 rounded-md hover:shadow-lg text-sm'>Go to home</router-link>
          </div>
        </div>
      </div>
      <nhnkcp-payment v-else-if='registrationPaymentModalComponent == "nhnkcp-payment"'
        :payment-params-prop='paymentParams'
        :is-modal-payment='true'
        :is-single-order='true'
        :is-korean='isKorean'
        @payment-success='paymentSuccess'
        @payment-fail='paymentFail' />
      <iamport-payment v-else-if='registrationPaymentModalComponent == "iamport-payment"'
        :payment-params-prop='paymentParams'
        :is-modal-payment='true'
        :is-single-order='true'
        :is-korean='isKorean'
        @payment-success='paymentSuccess'
        @payment-fail='paymentFail' />
      <payment-fail v-else-if='registrationPaymentModalComponent == "payment-fail"'
        :is-modal-payment='true'
        :is-korean='isKorean' />
      <payment-success v-else-if='registrationPaymentModalComponent == "payment-success"'
        :payment-params-prop='paymentSuccessParams'
        :is-modal-payment='true'
        :is-korean='isKorean'
        @payment-finished='paymentFinished'
        @payment-fail='paymentFail' />
      <registration-done-message v-else-if='registrationPaymentModalComponent == "registration-done-message"'
        class='max-w-2xl bg-gray-100 rounded-md p-16' 
        :is-korean='isKorean' />
    </modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { XIcon } from '@vue-hero-icons/outline'
import numbro    from 'numbro'
import cloneDeep from 'lodash/cloneDeep'
import camelCase from 'lodash/camelCase'
import jsonLogic from 'json-logic-js'
import { mapFields } from 'vuex-map-fields'
import dayjs from 'dayjs'

const camelcaseKeys = require('camelcase-keys')

export default {
  name: 'RegistrationAavbm',
  components: {
    XIcon,
    'registration-done-message': () => import('@/components/icola2023/WelcomeReceptionRegistrationDoneMessage.vue'), 
    'edit-form-field': () => import('@/components/edit-form/EditFormField.vue'), 
    'iamport-payment' : () => import( '@/components/iamport-payment/IamportPayment.vue'),
    'nhnkcp-payment' : () => import( '@/components/nhnkcp-payment/NhnkcpPayment.vue'),
    'payment-fail' : () => import( '@/components/payments/PaymentFail.vue'),
    'payment-success' : () => import( '@/components/payments/PaymentSuccess.vue'),
  },
  data () {
    return {
      registeredUserType: '',
      validPhoneNumbers: [],
      couponCode: '',
      enteringCouponCode: true,
      paymentParams: null,
      registrationPaymentModalComponent: '',
      paymentSuccessParams: null,
      paymentFailParams: null,
    }
  },
  watch: {
    'termsDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.terms = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'profileDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.profile = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'registrationDataDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.registrationData = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'profile.phoneNumber': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal && newVal.length > 3) {
          this.signupParams.password = newVal.slice(-4)
          this.signupParams.passwordConfirmation = newVal.slice(-4)
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapFields('users', [
      'signupParams',
      'profile',
      'terms',
      'registrationData',
      'hasValidToken',
    ]),
    ...mapState('products', [
      'products',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'showingEventSignupConfigurations',
      'showingEventRegistrationConfigurations',
      'themeTextColorStyle',
    ]),
    ...mapGetters('users', [
      'icolaWelcomeReceptionAtCapacity',
    ]),
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone')
    },
    isHorizontalLayout () {
      return !this.isMobile
    },
    termsDefault () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.termsDefault) ? this.showingEventSignupConfigurations.termsDefault : []
    },
    profileDefault () {
      return {
        'name': '',
        'country': '',
        'lastName': '',
        'extraInfo': {
          'hospitalType': '',
        },
        'firstName': '',
        'organization': '',
        'phoneNumber': '',
        'hospitalName': ''
      }
    },
    registrationDataDefault () {
      return {
        'specialRequestsForFood': '',
        'welcomeReceptionDepositor': '',
        'welcomeReceptionPaymentGateway': '',
        'welcomeReceptionFee': '',
        'welcomeReceptionPayment': false,
        'welcomeReceptionPaidAt': '',
        'welcomeReception': '',
        'welcomeReceptionExpectedDepositDate': ''
      }
    },
    showRequirementIndicatorOnTop () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.showRequirementIndicatorOnTop) ? this.showingEventRegistrationConfigurations.showRequirementIndicatorOnTop : false
    },
    requirementIndicatorHelperText () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.requirementIndicatorHelperText) ? this.showingEventRegistrationConfigurations.requirementIndicatorHelperText : 'is required.'
    },
    isKorean () {
      return !this.profile.country ||
             this.profile.country.toLowerCase() === 'kr'
    },
    registrationFeeBoxTitle () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationFeeBoxTitle) ? this.showingEventRegistrationConfigurations.registrationFeeBoxTitle : 'Registration Fees'
    },
    registrationButtonText () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationButtonText) ? this.showingEventRegistrationConfigurations.registrationButtonText : 'Register & Pay Registration Fees'
    },
    registrationDeadline () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationDeadline) ? this.showingEventRegistrationConfigurations.registrationDeadline : ''
    },
    registrationPeriodOverHtmlContent () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationPeriodOverHtmlContent) ? this.showingEventRegistrationConfigurations.registrationPeriodOverHtmlContent : '등록기간이 아닙니다.'
    },
    isRegistrationPeriod () {
      return !this.registrationDeadline ||
             (dayjs(this.registrationDeadline).isAfter(dayjs(new Date())) && this.registrationDeadline)
    },
    paymentGateway () {
      return this.registrationData ? this.registrationData.welcomeReceptionPaymentGateway : ''
    },
    alreadyRegisteredMyself () {
      return this.registrationData.welcomeReceptionPayment
    },
    registrationFeeAmount () {
      return this.registrationProduct ? this.registrationProduct.price.amount : 0
    },
    welcomeReceptionFeeAmount () {
      if (this.isKorean) {
        return 10000
      } else {
        return 10
      }
    },
    welcomeReceptionFeeCurrency () {
      if (this.isKorean) {
        return 'KRW'
      } else {
        return 'USD'
      }
    },
    totalRegistrationFee () {
      return this.welcomeReceptionFeeAmount
    },
    registrationProduct () {
      if (this.isKorean) {
        return this.products.find(product => product.name.includes('domestic'))
      } else {
        return this.products.find(product => product.name.includes('overseas'))
      }
    },
    needToCheckPhoneNumber () {
      return !this.validPhoneNumbers.every(phoneNumber => phoneNumber.isValidPhoneNumber)
    },
    needToAgreedTerms () {
      return !this.terms.filter(term => term.required).every(term => term.agreed)
    },
    additionalRequirements () {
      return {
        needToCheckPhoneNumber: this.needToCheckPhoneNumber,
        needToAgreedTerms: this.needToAgreedTerms,
      }
    },
    additionalRequirementsIndicators () {
      return (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.additionalRequirementsIndicators) ?
        this.showingEventSignupConfigurations.additionalRequirementsIndicators.concat()
        .filter((requirement) => {
          let condition = requirement.metaData.showCondition ? requirement.metaData.showCondition : false
          return jsonLogic.apply(condition, {additionalRequirements: this.additionalRequirements})
        }) : []
    },
    disabledRegistrationButton () {
      let allFields = [
        ...this.registrationFormFields,
        ...this.signupFormFields,
      ]
      return allFields.map(field => this.fieldValue(field.category, field.keyName)).some(value => !value ||
        typeof value === 'string' && !value ||
        typeof value === 'object' && !Object.keys(value).length ||
        Array.isArray(value) && !value.length) || 
        Object.values(this.additionalRequirements).some(requirement => requirement) ||
        this.registrationData.welcomeReception !== 'Attend'
    },
    disabledRegistrationButtonClass () {
      return this.disabledRegistrationButton ? 'opacity-50 cursor-not-allowed' : ''
    },
    canClickToCloseRegistrationModal () {
      return this.registrationPaymentModalComponent == 'payment-fail'
    },
    registrationFormFields () {
      return this.registrationFields.filter((field) => {
          let condition = field.metaData.showCondition ? field.metaData.showCondition : true
          return jsonLogic.apply(condition, {registrationData: this.registrationData, profile: this.profile, currentDateTime: this.currentDateTimeString})
        })
        .sort((fieldA, fieldB) => fieldA.sortOrder - fieldB.sortOrder)
    },
    signupFormFields () {
      return this.signupFields.filter((field) => {
          let condition = field.metaData.showCondition ? field.metaData.showCondition : true
          return jsonLogic.apply(condition, {registrationData: this.registrationData, profile: this.profile, currentDateTime: this.currentDateTimeString})
        })
        .sort((fieldA, fieldB) => fieldA.sortOrder - fieldB.sortOrder)
    },
    signupFields () {
      return camelcaseKeys([
        {
          "name": "Country",
          "category": "profile",
          "keyName": "country",
          "metaData": {
            "helperText": "",
            "placeholder": "",
            "selectableOptions": null
          },
          "inputType": "country",
          "sortOrder": 1,
          "titleHtml": "<p class='uppercase text-sm text-gray-900 w-full lg:w-60 flex-shrink-0 px-0 lg:px-2 py-2 lg:bg-gray-50'>Country<span class='text-red-600'>*</span></p>"
        },
        {
          "name": "ID (Email)",
          "category": "user",
          "keyName": "email",
          "metaData": {
            "helperText": "<div class='text-sm'>Please make sure you have entered your ID correctly as you can't modify it later.</div>",
            "placeholder": "",
            "selectableOptions": null
          },
          "inputType": "text",
          "sortOrder": 4,
          "titleHtml": "<p class='uppercase text-sm text-gray-900 w-full lg:w-60 flex-shrink-0 px-0 lg:px-2 py-2 lg:bg-gray-50'>ID (Email)<span class='text-red-600'>*</span></p>"
        },
        {
          "name": "Name (국문)",
          "category": "profile",
          "keyName": "name",
          "metaData": {
            "helperText": "",
            "placeholder": "",
            "showCondition": {
              "==": [
                {
                  "var": "profile.country"
                },
                "KR"
              ]
            },
            "selectableOptions": null
          },
          "inputType": "text",
          "sortOrder": 7,
          "titleHtml": "<p class='uppercase text-sm text-gray-900 w-full lg:w-60 flex-shrink-0 px-0 lg:px-2 py-2 lg:bg-gray-50'>Name (국문)<span class='text-red-600'>*</span></p>"
        },
        {
          "name": "First Name",
          "category": "profile",
          "keyName": "first_name",
          "metaData": {
            "helperText": "",
            "placeholder": "",
            "selectableOptions": null
          },
          "inputType": "text",
          "sortOrder": 8,
          "titleHtml": "<p class='uppercase text-sm text-gray-900 w-full lg:w-60 flex-shrink-0 px-0 lg:px-2 py-2 lg:bg-gray-50'>First Name<span class='text-red-600'>*</span></p>"
        },
        {
          "name": "Last Name",
          "category": "profile",
          "keyName": "last_name",
          "metaData": {
            "helperText": "",
            "placeholder": "",
            "selectableOptions": null
          },
          "inputType": "text",
          "sortOrder": 9,
          "titleHtml": "<p class='uppercase text-sm text-gray-900 w-full lg:w-60 flex-shrink-0 px-0 lg:px-2 py-2 lg:bg-gray-50'>Last Name<span class='text-red-600'>*</span></p>"
        },
        {
          "name": "Affiliation Type",
          "category": "profile",
          "key_name": "extra_info.hospital_type",
          "meta_data": {
            "helper_text": "",
            "placeholder": "",
            "show_condition": {
              "==": [
                {
                  "var": "profile.country"
                },
                "KR"
              ]
            },
            "selectable_options": [
              {
                "id": 1,
                "title": "수련병원",
                "value": "수련병원"
              },
              {
                "id": 2,
                "title": "병원",
                "value": "병원"
              },
              {
                "id": 3,
                "title": "의원",
                "value": "의원"
              },
              {
                "id": 4,
                "title": "군의관",
                "value": "군의관"
              },
              {
                "id": 5,
                "title": "보건의료원",
                "value": "보건의료원"
              },
              {
                "id": 5,
                "title": "보건소",
                "value": "보건소"
              },
              {
                "id": 5,
                "title": "기타",
                "value": "other"
              }
            ]
          },
          "input_type": "select",
          "sort_order": 10,
          "title_html": "<p class='uppercase text-sm text-gray-900 w-32 lg:w-60 flex-shrink-0 p-2 bg-gray-50'>Affiliation Type<span class='text-red-600'>*</span></p>"
        },
        {
          "name": "Affiliation",
          "category": "profile",
          "key_name": "organization_hospital_name",
          "meta_data": {
            "helper_text": "",
            "placeholder": "",
            "show_condition": {
              "and": [
                {
                  "==": [
                    {
                      "var": "profile.country"
                    },
                    "KR"
                  ]
                },
                {
                  "==": [
                    {
                      "var": "profile.extraInfo.hospitalType"
                    },
                    "수련병원"
                  ]
                }
              ]
            },
            "selectable_options": null,
            "main_affiliation_value": "hospital_name"
          },
          "input_type": "organization_hospital_name",
          "sort_order": 11,
          "title_html": "<p class='uppercase text-sm text-gray-900 w-32 lg:w-60 flex-shrink-0 p-2 bg-gray-50'>Affiliation<span class='text-red-600'>*</span></p>"
        },
        {
          "name": "Affiliation (국문)",
          "category": "profile",
          "key_name": "hospital_name",
          "meta_data": {
            "helper_text": "",
            "placeholder": "",
            "show_condition": {
              "and": [
                {
                  "==": [
                    {
                      "var": "profile.country"
                    },
                    "KR"
                  ]
                },
                {
                  "!==": [
                    {
                      "var": "profile.extraInfo.hospitalType"
                    },
                    "수련병원"
                  ]
                }
              ]
            },
            "selectable_options": null
          },
          "input_type": "text",
          "sort_order": 12,
          "title_html": "<p class='uppercase text-sm text-gray-900 w-32 lg:w-60 flex-shrink-0 p-2 bg-gray-50'>Affiliation (국문)<span class='text-red-600'>*</span></p>"
        },
        {
          "name": "Affiliation",
          "category": "profile",
          "key_name": "organization",
          "meta_data": {
            "helper_text": "",
            "placeholder": "",
            "show_condition": {
              "or": [
                {
                  "!==": [
                    {
                      "var": "profile.country"
                    },
                    "KR"
                  ]
                },
                {
                  "!==": [
                    {
                      "var": "profile.extraInfo.hospitalType"
                    },
                    "수련병원"
                  ]
                }
              ]
            },
            "selectable_options": null
          },
          "input_type": "text",
          "sort_order": 13,
          "title_html": "<p class='uppercase text-sm text-gray-900 w-32 lg:w-60 flex-shrink-0 p-2 bg-gray-50'>Affiliation<span class='text-red-600'>*</span></p>"
        },
        {
          "name": "Mobile Phone Number",
          "category": "profile",
          "keyName": "phone_number",
          "metaData": {
            "helperText": "",
            "placeholder": "",
            "selectableOptions": null
          },
          "inputType": "phone_number",
          "sortOrder": 21,
          "titleHtml": "<p class='uppercase text-sm text-gray-900 w-full lg:w-60 flex-shrink-0 px-0 lg:px-2 py-2 lg:bg-gray-50'>Mobile Phone Number<span class='text-red-600'>*</span></p>"
        },
        {
          "name": "Special Requests for Food",
          "category": "registration_data",
          "keyName": "special_requests_for_food",
          "metaData": {
            "helperText": "",
            "placeholder": "",
            "selectableOptions": [
              {
                "id": 1,
                "title": "None",
                "value": "None"
              },
              {
                "id": 2,
                "title": "Vegetarian",
                "value": "Vegetarian"
              },
              {
                "id": 3,
                "title": "Halal",
                "value": "Halal"
              },
              {
                "id": 4,
                "title": "Others",
                "value": "other"
              }
            ]
          },
          "inputType": "select",
          "sortOrder": 23,
          "titleHtml": "<p class='uppercase text-sm text-gray-900 w-full lg:w-60 flex-shrink-0 px-0 lg:px-2 py-2 lg:bg-gray-50'>Special Requests for Food<span class='text-red-600'>*</span></p>"
        }
      ], {deep: true})
    },
    registrationFields () {
      return camelcaseKeys([
        {
          "name": "Welcome Reception Attend (Thur, Sep 14th, 2023)",
          "category": "registration_data",
          "keyName": "welcome_reception",
          "metaData": {
            "helperText": "",
            "placeholder": "",
            "showCondition": {},
            "selectableOptions": [
              {
                "id": 1,
                "title": "Yes, I will (USD 10, KRW 10,000)",
                "value": "Attend"
              },
              {
                "id": 2,
                "title": "No, I will not",
                "value": "Not"
              }
            ]
          },
          "inputType": "radio-select",
          "sortOrder": 12,
          "titleHtml": ""
        },
        {
          "name": "Payment Type",
          "category": "registration_data",
          "keyName": "welcome_reception_payment_gateway",
          "metaData": {
            "helperText": "",
            "placeholder": "",
            "showCondition": {},
            "selectableOptions": [
              {
                "id": 1,
                "title": "Credit Card",
                "value": "nhnkcp-payment"
              },
              {
                "id": 2,
                "title": "Bank Transfer",
                "value": "bank_transfer"
              }
            ]
          },
          "inputType": "select",
          "sortOrder": 13
        },
        {
          "name": "Depositor",
          "category": "registration_data",
          "key_name": "welcome_reception_depositor",
          "meta_data": {
            "helper_text": "<p class='text-sm'><span class='font-semibold text-red-600'>The registered name and depositor name must match. </span>Otherwise, we will be unable to confirm your payment.</p>",
            "placeholder": "",
            "show_condition": {
              "and": [
                {
                  "===": [
                    {
                      "var": "registrationData.welcomeReceptionPaymentGateway"
                    },
                    "bank_transfer"
                  ]
                }
              ]
            },
            "selectable_options": null
          },
          "input_type": "text",
          "sort_order": 14
        },
        {
          "name": "Expected Deposit Date",
          "category": "registration_data",
          "key_name": "welcome_reception_expected_deposit_date",
          "meta_data": {
            "helper_text": "<p class='text-sm'><span class='font-semibold text-red-600'>Please allow for a few days to confirm the deposit.</span> You will receive the confirmation email.</p>",
            "placeholder": "Expected Deposit Date",
            "show_condition": {
              "and": [
                {
                  "===": [
                    {
                      "var": "registrationData.welcomeReceptionPaymentGateway"
                    },
                    "bank_transfer"
                  ]
                }
              ]
            },
            "selectable_options": null
          },
          "input_type": "date",
          "sort_order": 15
        }
      ], {deep: true})
    },
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus',
      'signup',
      'patchTerms',
      'patchRegistrationData',
      'createProfile',
      'getIcolaWelcomeReceptionAttendeesCount',
      'getRegistrationData',
      'registerIcolaWeclomeReceptionByUserEmail',
      'getRegistrationDataByUserEmail',
      'patchRegistrationDataByUserEmail',
    ]),
    ...mapActions('orders', [
      'createOrder',
    ]),
    ...mapActions('registrations', [
      'sendRegistrationEmails',
      'sendRegistrationEmailsByUserEmail',
    ]),
    ...mapActions('products', [
      'getProductsIcolaWelcomeReception',
    ]),
    updateFormFieldValue (category, keyName, value) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      if (category === 'profile') {
        if (arr.length === 2) { //extra info
          if (keyName.includes('phone_number')) { 
            this.profile[arr[0]][arr[1]] = value.phoneNumber
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === keyName)
            if (index > -1) {
              this.validPhoneNumbers[index].phoneNumber = value.phoneNumber
              this.validPhoneNumbers[index].isValidPhoneNumber = value.isValidPhoneNumber
            } else {
              this.validPhoneNumbers.push({
                keyName: keyName,
                phoneNumber: value.phoneNumber,
                isValidPhoneNumber: value.isValidPhoneNumber
              })
            }
          } else {
            this.profile[arr[0]][arr[1]] = value
          }
        } else if (arr.length === 1) {
          if (keyName === 'phone_number') {
            this.profile.phoneNumber = value.phoneNumber
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === keyName)
            if (index > -1) {
              this.validPhoneNumbers[index].phoneNumber = value.phoneNumber
              this.validPhoneNumbers[index].isValidPhoneNumber = value.isValidPhoneNumber
            } else {
              this.validPhoneNumbers.push({
                keyName: keyName,
                phoneNumber: value.phoneNumber,
                isValidPhoneNumber: value.isValidPhoneNumber
              })
            }
          } else if (keyName === 'address') {
            this.profile.address = value.address
            this.profile.postalCode = value.postalCode
          } else if (keyName === 'organization_hospital_name') {
            this.profile.organization = value.organization
            this.profile.hospitalName = value.hospitalName
          } else {
            this.profile[arr[0]] = value
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          this.signupParams[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.signupParams[arr[0]] = value
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          this.registrationData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.registrationData[arr[0]] = value
        }
      }
    },
    fieldValue (category, keyName) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      let val = ''
      if (category === 'profile') {
        if (arr.length === 2) {
          if (keyName.includes('phone_number')) { 
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === keyName)
            val = index > -1 && this.profile[arr[0]][arr[1]] ? {
              phoneNumber: this.validPhoneNumbers[index].phoneNumber,
              isValidPhoneNumber: this.validPhoneNumbers[index].isValidPhoneNumber
            } : null
          } else {
            val = this.profile[arr[0]][arr[1]]
          }
        } else if (arr.length === 1) {
          if (keyName === 'address') {
            val = this.profile.address && this.profile.postalCode ? {
              address: this.profile.address,
              postalCode: this.profile.postalCode,
            } : null
          } else if (keyName === 'phone_number') {
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === 'phone_number')
            val = index > -1 && this.profile[arr[0]] ? {
                phoneNumber: this.validPhoneNumbers[index].phoneNumber,
                isValidPhoneNumber: this.validPhoneNumbers[index].isValidPhoneNumber
            } : null
          } else if (keyName === 'organization_hospital_name') {
            val = this.profile.organization && this.profile.hospitalName ? {
              organization: this.profile.organization,
              hospitalName: this.profile.hospitalName,
            } : null
          } else {
            val = this.profile[arr[0]]
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          val = this.signupParams[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.signupParams[arr[0]]
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          val = this.registrationData[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.registrationData[arr[0]]
        }
      }
      return val
    },
    formattedFeeAmount (feeAmount) {
      return feeAmount ? `${numbro(feeAmount).format({
        thousandSeparated: true,
        mantissa: 0,
      })} ${this.welcomeReceptionFeeCurrency}` : 'Free'
    },
    updateWelcomeReceptionPaidData () {
      this.patchRegistrationData({ welcomeReceptionPayment: true, welcomeReceptionPaidAt: new Date() }).then(() => {
        this.sendRegistrationEmails({ email_type: 'welcome_reception_confirm' })
      })
    },
    updateWelcomeReceptionPaidDataByUserEmail () {
      this.patchRegistrationDataByUserEmail(
        {
          email: this.signupParams.email,
          registrationData: {
            welcomeReceptionPayment: true,
            welcomeReceptionPaidAt: new Date()
          } 
        }
      ).then(() => {
        this.sendRegistrationEmailsByUserEmail({ email: this.signupParams.email, email_type: 'welcome_reception_confirm' })
      })
    },
    registerWelcomeReception () {
      this.registrationData.welcomeReceptionFee = this.formattedFeeAmount(this.totalRegistrationFee)
      this.patchRegistrationData(this.registrationData).then(() => {
        this.createOrder({product: {
          order_amount: this.totalRegistrationFee,
          product_id: this.registrationProduct.id,
          payment_type: this.paymentGateway,
          event_id: this.showingEventId}
        }).then((order) => {
          if (order.status === 'Done') {
              this.updateWelcomeReceptionPaidData()
          } else {
            this.paymentParams = {
              pg:           this.paymentGateway,
              currency:     this.registrationProduct.price.priceUnit,
              amount:       order.price.amount,
              orderId:      order.hashedOrderId,
              orderName:    order.orderName,
              customerName: order.customerName ,
              customerPhoneNumber: this.profile.phoneNumber,
            }
            this.openRegistrationPaymentModal()
            if (this.paymentGateway === 'bank_transfer') {
              this.sendRegistrationEmails({ email_type: 'welcome_reception_pending' })
            }
          }
        }).catch(() => {
          this.$alert('에러가 발생했습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.', {
            type: 'warning'
          })
        })
      }).catch((error) => {
        console.error(error)
        this.$alert('에러가 발생했습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.', {
          type: 'warning'
        })
      })
    },
    registerWelcomeReceptionByUserEmail () {
      this.registrationData.welcomeReceptionFee = this.formattedFeeAmount(this.totalRegistrationFee)
      this.registerIcolaWeclomeReceptionByUserEmail({
        email: this.signupParams.email,
        profile: this.profile,
        terms: this.terms,
        registration_data: this.registrationData,
        product: {
          order_amount: this.totalRegistrationFee,
          product_id: this.registrationProduct.id,
          payment_type: this.paymentGateway,
          event_id: this.showingEventId
        }
      }).then((order) => {
        if (order.status === 'Done') {
          this.updateWelcomeReceptionPaidDataByUserEmail()
        } else {
          this.paymentParams = {
            pg:           this.paymentGateway,
            currency:     this.registrationProduct.price.priceUnit,
            amount:       order.price.amount,
            orderId:      order.hashedOrderId,
            orderName:    order.orderName,
            customerName: order.customerName ,
            customerPhoneNumber: this.profile.phoneNumber,
          }
          this.openRegistrationPaymentModal()
          if (this.paymentGateway === 'bank_transfer') {
            this.sendRegistrationEmailsByUserEmail({ email: this.signupParams.email, email_type: 'welcome_reception_pending' })
          }
        }
      }).catch((error) => {
        console.error(error)
        this.$alert('에러가 발생했습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.', {
          type: 'warning'
        })
      })
    },
    submitSignup () {
      this.signup().then(() => {
        let patchTerms = this.patchTerms()
        let createProfile = this.createProfile()
        Promise.all([patchTerms, createProfile]).then(() => {
          this.registeredUserType = 'new_user'
          this.registerWelcomeReception()
        })
      }).catch((error) => {
        if (error.response.data.error === 'UserExistError') {
          this.registeredUserType = 'exist_user'
          this.registerWelcomeReceptionByUserEmail()
        } else {
          console.error(error.response.data.error)
        }
      })
    },
    isFieldValutEmpty (field) {
      let value = this.fieldValue(field.category, field.keyName)
      return !value ||
        typeof value === 'string' && !value ||
        typeof value === 'object' && !Object.keys(value).length ||
        Array.isArray(value) && !value.length
    },
    initializePaymentModal () {
      if (this.paymentGateway === 'bank_transfer') {
        this.registrationPaymentModalComponent = 'bank-transfer'
      } else if (this.paymentGateway.includes('iamport')) {
        this.registrationPaymentModalComponent = 'iamport-payment'
      } else if (this.paymentGateway.includes('nhnkcp')) {
        this.registrationPaymentModalComponent = 'nhnkcp-payment'
      }
    },
    paymentFail (params) {
      // show payment fail with params
      this.paymentFailParams = params
      this.registrationPaymentModalComponent = 'payment-fail'
    },
    paymentSuccess (params) {
      // show payment success with params
      this.paymentSuccessParams = params
      this.registrationPaymentModalComponent = 'payment-success'
    },
    paymentFinished () {
      // show registration done message
      this.registrationPaymentModalComponent = 'registration-done-message'
      // payment done
      if (this.registeredUserType === 'new_user') {
        this.getRegistrationData().then(() => {
          if (!this.alreadyRegisteredMyself) {
            this.updateWelcomeReceptionPaidData()
          }
        })
      } else if (this.registeredUserType === 'exist_user') {
        this.getRegistrationDataByUserEmail({email: this.signupParams.email}).then((resp) => {
          if (!resp.welcomeReceptionPayment) {
            this.updateWelcomeReceptionPaidDataByUserEmail()
          }
        })
      } else {
        // do nothing
      }
    },
    redirectIfFinished () {
      if (this.registrationPaymentModalComponent == 'registration-done-message') {
        this.$router.push({name: 'Main'})
      }
    },
    openRegistrationPaymentModal () {
      this.$modal.show('registration-payment-modal')
    },
    closeRegistrationPaymentModal () {
      this.$modal.hide('registration-payment-modal')
    },
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
    this.getIcolaWelcomeReceptionAttendeesCount()
    this.registrationData.welcomeReception = 'Attend'
    this.getProductsIcolaWelcomeReception()
    this.checkTokenStatus().then(() => {
      if (this.hasValidToken) {
        this.getRegistrationData()
      }
    })
    
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
    this.closeRegistrationPaymentModal()
    
  },  
}
</script>

<style type='scss' scoped>
.submit-button {
  @apply text-white;
  background-color: var(--eventMainColor);
}

.tagline-with-background {
  font-size:10px;
  background-color:#345CA1;
}

@media (min-width: 768px) {
  .tagline-with-background {
    font-size:14px;
  }
}
@media (min-width: 1024px) {
  .tagline-with-background {
    font-size:16px;
  }
}
</style>
