import { render, staticRenderFns } from "./RegistrationAavbm.vue?vue&type=template&id=aaa8bbe8&scoped=true"
import script from "./RegistrationAavbm.vue?vue&type=script&lang=js"
export * from "./RegistrationAavbm.vue?vue&type=script&lang=js"
import style0 from "./RegistrationAavbm.vue?vue&type=style&index=0&id=aaa8bbe8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaa8bbe8",
  null
  
)

export default component.exports